import MatchList from 'components/MatchList';
import PillTabList from 'components/PillTabList';
import WidgetBet from 'components/WidgetBet';
import WidgetTvProgram from 'components/WidgetTvProgram';
import useCompetitions from 'hooks/useCompetitions';
import React, {FC, Ref, RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';

import {Tabs, TabPanel} from 'react-tabs';
import {groupMatchesByCompetition} from 'utils/matchFns';

import {BetData, BetProviderData, MatchSimple, Sport, TVProgramEvent} from 'utils/types';

import './styles.scss';

const scoresBtnListener = (event: Event) => {

  if (!window.s24 || !window.s24.matchcenterHpWidgetMobileSlots)
    return;

  window.googletag = window.googletag || {cmd: []};
  if (document.body.classList.contains('has-open-matchcenter')) {
    window.googletag.cmd.push(() => {
      window.paRefresh(Object.values(window.s24.matchcenterHpWidgetMobileSlots));
    });
  // } else {
  //   window.googletag.cmd.push(() => {
  //     window.googletag.pubads().clear(Object.values(window.s24.matchcenterHpWidgetMobileSlots));
  //   });
  }
}

const MatchcenterHomepageWidget: FC<{
  matches: MatchSimple[];
  tvData: TVProgramEvent[];
  betData?: BetData[];
  betProvider?: BetProviderData;
  root: HTMLElement | null;
  index?: number;
  isMobile?: boolean;
}> = ({
  matches,
  tvData,
  betData,
  betProvider,
  root,
  index,
  isMobile = false,
}: {
  matches: MatchSimple[];
  tvData: TVProgramEvent[];
  betData?: BetData[];
  betProvider?: BetProviderData;
  root: HTMLElement | null;
  index?: number;
  isMobile?: boolean;
}) => {
  const [competitionsState] = useCompetitions();
  const {data: competitions} = competitionsState;
  const slot = useRef<any | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const matchGroups = useMemo(
    () => groupMatchesByCompetition(matches, competitions),
    [matches, competitions]
  );

  // const divId = useMemo(
  //   () => `matchcenter-homepage-third-party-bottom${typeof index === 'number' ? `-${index}` : ''}`,
  //   [index]
  // );

  const divId = useMemo(
    () => `matchcenter-matchlist-third-party${typeof index === 'number' ? `-${index}` : ''}`,
    [index]
  );

  const handleClick = useCallback((divOpen) => {
    // console.log("isOpen in click", divOpen)
    if (document.body.classList.contains('has-open-matchcenter')){
    setIsOpen(true);
    // console.log("slots", slot.current, divId, Object.values(window.s24.matchcenterHpWidgetMobileSlots))
    window.googletag = window.googletag || {cmd: []};
// console.log('slots2', window.s24.matchcenterHpWidgetMobileSlots)
    
    window.googletag.cmd.push(() => {

      window.googletag.display(divId);

      window.paRefresh(Object.values(window.s24.matchcenterHpWidgetMobileSlots))
    })
  }
  }, [ divId])

  // useLayoutEffect(() => {

  //   if (!slot.current) {
  //     window.googletag = window.googletag || {cmd: []};
  //     window.googletag.cmd.push(() => {
  //       const mapping = isMobile
  //         ? window.googletag
  //             .sizeMapping()
  //             .addSize([768, 0], [])
  //             .addSize([0, 0], [[300, 250]])
  //             .build()
  //         : window.googletag
  //             .sizeMapping()
  //             .addSize([768, 0], [[300, 250]])
  //             .addSize([0, 0], [])
  //             .build();

  //       /* slot.current = window.googletag
  //         .defineSlot('/4834629/sport24.gr/HP_300x250_matchcenter', [[300, 250]], divId)
  //         .defineSizeMapping(mapping)
  //         .addService(window.googletag.pubads())
  //         .setTargeting('article', '')
  //         .setTargeting('keywords', [])
  //         .setTargeting('category', 'homepage')
  //         .setTargeting('type', 'homepage')
  //         .setTargeting('isAmp', 'false')
  //         .setTargeting('isMobile', isMobile ? 'true' : 'false'); */
        
  //       if (!isMobile) {
  //         // display ad immediately only on desktop
  //         window.googletag.display(divId);
  //       } else {
  //         // if mobile, add click listener to scores button
  //         window.s24 = window.s24 || {};
  //         window.s24.matchcenterHpWidgetMobileSlots = {...(window.s24.matchcenterHpWidgetMobileSlots || {}), [divId]: slot.current};
  //         const btn = document.querySelector('.main-menu__scores-button');

  //         if (btn)
  //           btn.addEventListener('click', scoresBtnListener);

  //       }

  //     });
  //   }

  //   return () => {
  //     // destroy all slots in component
  //     window.googletag = window.googletag || {cmd: []};
  //     window.googletag.cmd.push(() => {
  //       if (slot.current) {
  //         window.googletag.destroySlots([slot.current]);
  //         slot.current = null;
  //       }
  //     });

  //     // remove click listener from scores button
  //     window.s24 = window.s24 || {};
  //     if (window.s24.matchcenterHpWidgetMobileSlots) delete window.s24.matchcenterHpWidgetMobileSlots;
  //     const btn = document.querySelector('.main-menu__scores-button');

  //     if (btn)
  //       btn.addEventListener('click', scoresBtnListener);

  //   };
  // }, [divId, isMobile]);


  return (
    root &&
    createPortal(
      <section className="MatchcenterHomepageWidget">
        <h1 className="MatchcenterHomepageWidget__header">
          <a href="/matchcenter/">MATCHCENTER</a>
        </h1>
        <Tabs className="MatchcenterHomepageWidget__tabs"
          // onSelect={(ind, prevIndex) => {
          //   if(ind !== prevIndex) {
          //     handleClick(isOpen);
          //   }
          // }}
        >
          {index!==2 && PillTabList({/* eslint-disable no-nested-ternary */

            tabs:
              betData && !!betData.length
                ? ['SCORES', 'LIVE BET', 'TV PROGRAM']
                : ['SCORES', 'TV PROGRAM'],
          })}
          <TabPanel>
            <MatchList matchGroups={matchGroups} showAd isOpen={isOpen} handleClick={handleClick} index={index} isMobile={isMobile} />
            <a href="/matchcenter/" className="MatchcenterHomepageWidget__button">
              ΟΛΟΙ ΟΙ ΑΓΩΝΕΣ
            </a>
          </TabPanel>
          {betData && !!betData.length && (
            <TabPanel>
              {betData.map(matchBetData => (
                <WidgetBet key={matchBetData.matchUrl} betData={matchBetData} />
              ))}
              <p className="MatchcenterHomepageWidget__betDisclaimer">
                21+ Παίξτε Υπεύθυνα. Κίνδυνος εθισμού και απώλειας περιουσίας. ΚΕΘΕΑ 2109237777.
              </p>
              {betProvider && betProvider.imageUrl && (
                <div className="MatchcenterHomepageWidget__betProvider">
                  <span className="MatchcenterHomepageWidget__betProviderLabel">POWERED BY</span>
                  {betProvider.clickUrl ? (
                    <a
                      className="MatchcenterHomepageWidget__betProviderLogo"
                      href={betProvider.clickUrl}
                      target="_blank"
                      rel="external nofollow noopener noreferrer"
                    >
                      <img src={betProvider.imageUrl} alt={betProvider.name} />
                    </a>
                  ) : (
                    <div className="MatchcenterHomepageWidget__betProviderLogo">
                      <img src={betProvider.imageUrl} alt={betProvider.name} />
                    </div>
                  )}
                </div>
              )}
            </TabPanel>
          )}
          {index!==2 && (
          <TabPanel>
            <WidgetTvProgram tvData={tvData} />
            {/* eslint-disable react/jsx-no-target-blank */}
            <a
              className="MatchcenterHomepageWidget__button"
              href="https://www.sport24.gr/tvprogram/"
              target="_blank"
            >
              ΑΝΑΛΥΤΙΚΟ ΠΡΟΓΡΑΜΜΑ TV
            </a>
          </TabPanel>
          )}

        </Tabs>
      </section>,
      root
    )
  );
};

export default React.memo(MatchcenterHomepageWidget);
